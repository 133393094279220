import React from "react";
import {
    Avatar, Box, Chip, Divider, Grid, Link,
    ListItem, ListItemAvatar, ListItemText, makeStyles, Typography
} from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import clsx from 'clsx';
import * as _ from 'lodash';
import EventStore from "../../stores/EventStore";

export default function EventUserRegister(
    {eventData, userData, showUserDetails, checkLabel, checkDescription, onRowClick, isChecked, onCheckClick}) {
    const classes = useStyles();

    // 行クリック時
    const onListItemClick = async (e) => {
        e.stopPropagation();
        if (_.isFunction(onRowClick)) {
            await onRowClick(userData);
        }
    };

    // チェッククリック時
    const onCheckBoxClick = async (e) => {
        e.stopPropagation();    // 親Viewの行Clickが発生しないようにキャンセル
        if (_.isFunction(onCheckClick)) {
            await onCheckClick(userData);
        }
    };

    return (
        <React.Fragment>
            <ListItem alignItems="flex-start" button={_.isFunction(onRowClick)} onClick={onListItemClick}>
                {userData['number'] && (
                    <ListItemAvatar>
                        <Typography variant="h1" className={classes.number}>{userData['number']}</Typography>
                    </ListItemAvatar>
                )}
                <ListItemText disableTypography={true}>
                    <Grid container direction="row">
                        <Grid item xs={10}>
                            <Typography variant="h3">{userData['name']}</Typography>
                            {showUserDetails ? (
                                <>
                                    <Typography variant="body1">{userData['vehicle_model']}</Typography>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item>
                                            <Typography variant="body1">
                                                <Link href={'tel:' + userData['mobile_number']}>{userData['mobile_number']}</Link>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">（
                                                {userData["device"] ? userData["device"]["model_name"] : '--'}/{userData["device"] ? userData["device"]["os_version"] : '--'}
                                            ）</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Typography variant="body1">{userData['vehicle_model']}</Typography>
                            )}
                            <Box>
                                {userData["categories"].map(categoryId => (
                                    <Chip
                                        key={userData["id"] + categoryId}
                                        label={EventStore.getCategoryName(eventData, categoryId)}
                                        size="small"
                                        className={classes.categoryLabel}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            {checkLabel && (
                                <>
                                    <Typography variant="body2" className={classes.checkLabel}>{checkLabel}</Typography>
                                    <Avatar variant="rounded"
                                            onClick={onCheckBoxClick}
                                            className={clsx(classes.doneIcon, isChecked && classes.doneIconDone)}>
                                        <DoneOutlineIcon />
                                    </Avatar>
                                </>
                            )}
                            {checkDescription && (
                                <Typography variant="body2" className={classes.descriptionLabel}>{checkDescription}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </ListItemText>
            </ListItem>
            <Divider />
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    number: {
        textAlign: "center",
        paddingRight: '0.5rem',
    },
    categoryLabel: {
        fontSize: '0.65rem'
    },
    checkLabel: {
        fontSize: '0.5rem'
    },
    descriptionLabel: {
        fontSize: '0.55rem'
    },
    doneIcon: {
        cursor: "pointer"
    },
    doneIconDone: {
        color: '#fff',
        backgroundColor: theme.palette.warning.main,
    }
}));
