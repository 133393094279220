import React from 'react';
import * as Firebase from '../../stores/data_sources/Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import AdminBaseTemplate from "../../templates/AdminBaseTemplate";
import {Grid} from "@material-ui/core";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/home',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: Firebase.instance.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: {
                status: true
            },
            requireDisplayName: false,
        }
    ],
};

export default function LoginScreen() {
    return (
        <AdminBaseTemplate title="KomaTour管理">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={Firebase.instance.auth()} />
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}
