import React, {useEffect, useState, useContext, useCallback} from "react";
import {useParams} from "react-router-dom";
import {makeStyles, Grid, IconButton, Icon, Typography, List} from "@material-ui/core";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import EventStore from "../../../stores/EventStore";
import * as Styles from "../../../components/admin/Styles";
import EventUserRegister from "../../../components/admin/EventUserRegister";
import EventTitleValueDisplay from "../../../components/admin/EventTitleValueDisplay";
import * as Utils from "../../../Utils";

export default function EventRegisterReceptionScreen() {
    const classes = useStyles();
    const params = useParams();
    const flashMessageContext = useContext(FlashMessageContext);
    
    const [title, setTitle] = useState('イベント受付');
    const [showLoading, setShowLoading] = useState(false);
    const [eventData, setEventData] = useState({});
    const [userList, setUserList] = useState([]);
    const [userListWithoutStaff, setUserListWithoutStaff] = useState([]);

    const eventId = params["event_id"];
    
    // 登録済みユーザー検索
    const retrieveUsers = useCallback(async () => {
        try {
            setShowLoading(true);
            
            // イベント登録済みユーザー検索
            const eventRegisteredUsers = await EventStore.retrieveEventRegisteredUsers(eventId);
            setUserList(eventRegisteredUsers);

            // スタッフ除外ユーザーリスト
            setUserListWithoutStaff(eventRegisteredUsers.filter(user => !Utils.isStaff(user["number"])));
        } catch (e) {
            console.error(e);
        } finally {
            setShowLoading(false);
        }
    }, [eventId]);
    
    // 初期処理
    useEffect(() => {
        (async () => {
            setShowLoading(true);
            
            // イベント情報検索
            const event = await EventStore.retrieveEvent(eventId);
            setTitle(event["name"] + '- イベント受付');
            setEventData(event);
            
            // 登録済みユーザー検索
            await retrieveUsers();
            
            setShowLoading(false);
        })();
        
        return () => {
            flashMessageContext.hideMessage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // ユーザー選択時
    const onUserClick = async (user) => {
        try {
            setShowLoading(true);
            
            let isCheckIn = true;
            if (user["is_reception_done"] === true) {
                isCheckIn = false;
            }
            await EventStore.changeUserCheckInReceptionStatus(eventData["id"], user["id"], isCheckIn);
            
            flashMessageContext.showMessage("受付しました", "success");
            
        } catch (error) {
            console.error("イベント受付エラー");
            console.error(error);
        } finally {
            setShowLoading(false);
            await retrieveUsers();
        }
    };
    
    return (
        <AdminBaseTemplate
            title={title}
            isShowLoadingModal={showLoading}
            rightButton={(
                <IconButton
                    variant="contained"
                    color="inherit"
                    onClick={retrieveUsers}
                >
                    <Icon>refresh</Icon>
                </IconButton>
            )}
        >
            <Grid container className={classes.container} spacing={2}>
                {/* サマリ */}
                <Grid item xs={12}>
                    <Typography variant="h6">受付状況</Typography>
                    <EventTitleValueDisplay
                        title="受付済"
                        primaryValue={`${userListWithoutStaff.filter(user => user["is_reception_done"]).length}/${userListWithoutStaff.length}名`}
                    />
                    <small>※スタッフ除く</small>
                </Grid>

                {/* 検索・フィルター */}
                {/*<Grid item xs={12}>*/}
                    {/*<Typography variant="body2">(検索・フィルターはまだ未実装です)</Typography>*/}
                {/*</Grid>*/}
                
                {/* 参加者一覧 */}
                <Grid item xs={12}>
                    <Typography variant="h6">イベント参加登録者一覧</Typography>
                    
                    <List className={classes.listContainer}>
                        {userList.map(user => (
                            <EventUserRegister
                                key={user["id"]}
                                eventData={eventData}
                                userData={user}
                                checkLabel={"参加受付"}
                                checkDescription={user["is_reception_done"] && user["reception_at"] ?
                                    "受付:" + Utils.formatShortDateTime(Utils.parseDate(user["reception_at"])) : ""}
                                onCheckClick={onUserClick}
                                isChecked={user["is_reception_done"]}
                            />
                        ))}
                    </List>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    listContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));
