import axios from "axios";

/**
 * ユーザー情報操作
 */
function createUserStore() {
    return {
        // PUSH通知を送信する
        async sendPushNotification(eventId, message, url) {
            // データ作成
            const data = {
                eventId: eventId,
                title: process.env.REACT_APP_PUSH_TITLE,
                message: message,
                data: {},
            };
            if (url) {
                data.data.url = url;
            }

            // Expo API 経由でPUSH通知送信
            await axios.post(process.env.REACT_APP_PUSH_API_URL, data);
        },
    }
}
export default createUserStore();
