import React, {useEffect, useState, useContext, useCallback} from "react";
import {useParams} from "react-router-dom";
import {makeStyles, Grid, IconButton, Icon, Typography, List} from "@material-ui/core";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import EventStore from "../../../stores/EventStore";
import * as Styles from "../../../components/admin/Styles";
import EventUserRegister from "../../../components/admin/EventUserRegister";
import * as Utils from "../../../Utils";

export default function EventRegisterCheckPointScreen() {
    const classes = useStyles();
    const params = useParams();
    const flashMessageContext = useContext(FlashMessageContext);
    
    const [title, setTitle] = useState('CP受付');
    const [showLoading, setShowLoading] = useState(false);
    const [eventData, setEventData] = useState({});
    const [userList, setUserList] = useState([]);
    
    const eventId = params["event_id"];
    const checkPointId = params["check_point_id"];
    
    // イベント情報 & 登録済みユーザー検索
    const retrieveUsers = useCallback(async () => {
        try {
            setShowLoading(true);
            
            // イベント情報 with 登録＆受付済みユーザー検索
            const eventRegisteredUsers = await EventStore.retrieveEventRegisteredUsers(eventId, true);
            setUserList(eventRegisteredUsers);
        } catch (e) {
            console.error(e);
        } finally {
            setShowLoading(false);
        }
    }, [eventId]);
    
    // 初期処理
    useEffect(() => {
        (async () => {
            setShowLoading(true);
            
            // イベント情報検索
            const event = await EventStore.retrieveEvent(eventId);
            setTitle(event["name"] + '- イベント受付');
            setEventData(event);
            
            // CP名を取得してタイトルへ設定
            const checkPoints = await EventStore.retrieveEventCheckPoints(eventId);
            const checkPoint = checkPoints.filter((v) => v["id"] === checkPointId)[0];  // 無いとおかしい！
            setTitle(checkPoint["name"] + '- CP受付');
            
            // 登録済みユーザー検索
            await retrieveUsers();
            
            setShowLoading(false);
        })();

        return () => {
            flashMessageContext.hideMessage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // ユーザー選択時
    const onUserClick = async (user) => {
        try {
            setShowLoading(true);
            
            let isCheckIn = true;
            if (!!user["check_points"] && !!user["check_points"][checkPointId]) {
                isCheckIn = false;
            }
            await EventStore.changeUserCheckPointStatus(eventData["id"], user["id"], checkPointId, isCheckIn);
            
            flashMessageContext.showMessage("受付しました", "success");
            
        } catch (error) {
            console.error("チェックポイント受付エラー");
            console.error(error);
        } finally {
            setShowLoading(false);
            await retrieveUsers();
        }
    };
    
    return (
        <AdminBaseTemplate
            title={title}
            isShowLoadingModal={showLoading}
            rightButton={(
                <IconButton
                    variant="contained"
                    color="inherit"
                    onClick={retrieveUsers}
                >
                    <Icon>refresh</Icon>
                </IconButton>
            )}
        >
            <Grid container className={classes.container} spacing={2}>
                {/* 検索・フィルター */}
                <Grid item xs={12}>
                    {/*<Typography variant="body2">(検索・フィルターはまだ未実装です)</Typography>*/}
                </Grid>
                
                {/* 参加者一覧 */}
                <Grid item xs={12}>
                    <Typography variant="h6">イベント参加登録者一覧</Typography>
                    
                    <List className={classes.listContainer}>
                        {userList.map(user => (
                            <EventUserRegister
                                key={user["id"]}
                                eventData={eventData}
                                userData={user}
                                checkLabel={"CP受付"}
                                checkDescription={
                                    user["check_points"] && user["check_points"][checkPointId] &&
                                    user["check_points"][checkPointId]["check_in_at"] ?
                                        "受付:" + Utils.formatShortDateTime(Utils.parseDate(user["check_points"][checkPointId]["check_in_at"])) : ""}
                                onCheckClick={onUserClick}
                                isChecked={!!user["check_points"] && !!user["check_points"][checkPointId]}
                            />
                        ))}
                    </List>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    listContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));
