import React from 'react'
import {makeStyles, Box, Divider, Grid, Link} from "@material-ui/core";
import clsx from "clsx";

export default function EventTitleValueDisplay({title, primaryValue, secondaryValue, onPrimaryClick}) {
    const classes = useStyles();
    
    return (
        <Grid container direction="row" className={classes.mainContainer}>
            <Grid item xs={3} className={classes.titleContainer}>
                {!!onPrimaryClick ? (
                    <Link href="#" onClick={onPrimaryClick}>
                        <Box className={classes.titleAnchor}>{title}</Box>
                    </Link>
                ) : (
                    <Box className={classes.title}>{title}</Box>
                )}
            </Grid>
            <Grid item xs={9} className={clsx(classes.valueContainer, !!secondaryValue && classes.valueContainerNoFlex)}>
                {!!secondaryValue ? (
                    <Box className={classes.secondaryValue}>{secondaryValue}</Box>
                ) : null }
                <Box className={classes.primaryValue}>{primaryValue}</Box>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        marginBottom: 1,
    },
    titleContainer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        padding: '0.6rem',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    titleAnchor: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.secondary.contrastText,
        textDecoration: 'underline',
    },
    valueContainer: {
        padding: '0.6rem',
        display:'flex',
        alignItems:'center',
    },
    valueContainerNoFlex: {
        display:'block',
    },
    primaryValue: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    secondaryValue: {
        fontSize: '1.1rem',
        fontWeight: 'normal',
        marginBottom: '-0.3rem'
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
    }
}));
