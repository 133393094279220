import React, {useEffect, useState, useContext, useCallback} from "react";
import {useParams} from "react-router-dom";
import {makeStyles, Grid, IconButton, Icon, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import EventStore from "../../../stores/EventStore";
import EventStore2, {EventBasicData, EventCheckPointData} from "../../../stores/EventStore2";
import * as Styles from "../../../components/admin/Styles";
import EventUserRegister from "../../../components/admin/EventUserRegister";
import * as Utils from "../../../Utils";
import * as GsDays from "./GSDaysSpecial";

export default function EventRegisterCheckPointScreen() {
    const classes = useStyles();
    const params = useParams();
    const flashMessageContext = useContext(FlashMessageContext);

    const [title, setTitle] = useState<string>('CPステータス(ユーザー毎)');
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [eventData, setEventData] = useState<EventBasicData | null>(null);
    const [checkPointList, setCheckPointList] = useState<EventCheckPointData[]>([]);
    const [userList, setUserList] = useState<any[]>([]);

    // @ts-ignore
    const eventId = params["event_id"] as string;

    // イベント情報 & 登録済みユーザー検索
    const retrieveUsers = useCallback(async () => {
        try {
            setShowLoading(true);

            // イベント情報 with 登録＆受付済みユーザー検索
            const eventRegisteredUsers = await EventStore.retrieveEventRegisteredUsers(eventId, true);

            // TODO: GSDaysの特別対応：各ユーザーのディーラー情報を付与する
            if (eventId === "bmw_gsdays_2024_off") {
                for (let i = 0; i < eventRegisteredUsers.length; i++) {
                    // @ts-ignore
                    eventRegisteredUsers[i]["dealer"] = GsDays.gsDaysNumberDealerOff[eventRegisteredUsers[i]["number"]];
                }
            } else if (eventId === "bmw_gsdays_2024_on") {
                for (let i = 0; i < eventRegisteredUsers.length; i++) {
                    // @ts-ignore
                    eventRegisteredUsers[i]["dealer"] = GsDays.gsDaysNumberDealerOn[eventRegisteredUsers[i]["number"]];
                }
            }

            setUserList(eventRegisteredUsers);
        } catch (e) {
            console.error(e);
        } finally {
            setShowLoading(false);
        }
    }, [eventId]);

    // 初期処理
    useEffect(() => {
        (async () => {
            setShowLoading(true);

            // イベント情報検索
            const event = await EventStore2.retrieveEvent(eventId);
            if (!event) {
                throw new Error("指定されたイベントが見つかりませんでした");
            }
            setTitle(event.name + '- CPステータス(ユーザー毎)');
            setEventData(event);

            // チェックポイント取得（ソートする）
            const checkPoints =
                event.check_points?.sort((a: EventCheckPointData, b: EventCheckPointData) => a.order - b.order) ?? [];
            setCheckPointList(checkPoints);

            // 登録済みユーザー検索
            await retrieveUsers();

            setShowLoading(false);
        })();

        return () => {
            flashMessageContext.hideMessage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminBaseTemplate
            title={title}
            isShowLoadingModal={showLoading}
            rightButton={(
                // @ts-ignore
                <IconButton
                    variant="contained"
                    color="inherit"
                    onClick={retrieveUsers}
                >
                    <Icon>refresh</Icon>
                </IconButton>
            )}
        >
            <Grid container className={classes.container} spacing={2}>
                {/* 参加者一覧 */}
                <Grid item xs={12}>
                    <TableContainer>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow style={{backgroundColor:'lightgray'}}>
                                    <TableCell>ゼッケン</TableCell>
                                    <TableCell>名前</TableCell>
                                    <TableCell>車種</TableCell>
                                    {(eventId === "bmw_gsdays_2024_off" || eventId === "bmw_gsdays_2024_on") ? (
                                        <TableCell>ディーラー</TableCell>
                                    ) : null}
                                    {checkPointList.map(checkPoint => (
                                        <TableCell key={`header-${checkPoint.id}`}>{checkPoint.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map(user => (
                                    <TableRow key={`data-row-${user.id}`}>
                                        <TableCell style={{fontWeight:'bold'}}>{user.number}</TableCell>
                                        <TableCell style={{fontWeight:'bold'}}>{user.name}</TableCell>
                                        <TableCell>{user.vehicle_model}</TableCell>
                                        {(eventId === "bmw_gsdays_2024_off" || eventId === "bmw_gsdays_2024_on") ? (
                                            <TableCell>{user.dealer}</TableCell>
                                        ) : null}
                                        {checkPointList.map(checkPoint => (
                                            <TableCell key={`data-cell-${checkPoint.id}`} align={"center"}
                                                       style={{
                                                           fontSize:'1.1rem',
                                                           borderLeft: "1px solid rgba(224, 224, 224, 1)",

                                            }}
                                            >
                                                {user["check_points"] && user["check_points"][checkPoint.id] ? "◎" : ""}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    listContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));
