import firebase from "firebase/app";
import * as Firebase from '../stores/data_sources/Firebase';

/**
 * EventStore TypeScript移行用ファイル
 * EventStore.jsとゆくゆくはマージする
 */
function createEventStore2() {
    const db = Firebase.instance.firestore();
    const storage = Firebase.instance.storage();

    return {
        /**
         * イベント読み込み
         * 注意！同名メソッドがEventStore.jsにあり
         * @param eventId
         */
        async retrieveEvent(eventId: string): Promise<EventBasicData | null> {
            // イベント本体
            const eventRef = await db.collection(Firebase.V + 'events').doc(eventId).get();
            if (!eventRef.exists) {
                return null;
            }
            const eventData = eventRef.data() as EventBasicData;

            // チェックポイント
            eventData.check_points = [];
            const checkPointsRef = await db.collection(Firebase.V + 'events').doc(eventId)
                .collection("check_points").get();
            for (let i = 0; i < checkPointsRef.size; i++) {
                const checkPointData = checkPointsRef.docs[i].data() as EventCheckPointData;
                checkPointData.id = checkPointsRef.docs[i].id;
                eventData.check_points.push(checkPointData);
            }

            return eventData;
        },

        /**
         * イベント登録
         */
        async registerEvent(eventData: EventBasicData, isNew: boolean = true): Promise<string | undefined> {
            // ユーザー権限チェック
            const userInfo = await Firebase.retrieveCurrentUser();
            if (!userInfo || !AdminUserRoleValues.includes(userInfo.role)) {
                return "ユーザー権限無し";
            }

            // イベントID重複チェック
            const existsDataSs = await db.collection(Firebase.V + 'events').doc(eventData.short_id).get();
            if (isNew){
                if (existsDataSs.exists) {
                    return "すでに同じイベントIDが登録されています"
                }
                eventData.admin_uid = userInfo.user_id;
            } else {
                eventData.admin_uid = (existsDataSs.data() as EventBasicData).admin_uid;
            }

            // バナー画像ファイルアップロード
            if (eventData.banner_img instanceof File) {
                const bannerImgFile = eventData.banner_img as File;
                const bannerImgFileExt = bannerImgFile.name.split(".").pop();
                const bannerImgFileRef = await storage.ref(Firebase.V + 'events').child(eventData.short_id)
                    .child(`${eventData.short_id}_banner.${bannerImgFileExt}`);
                await bannerImgFileRef.put(bannerImgFile);
                eventData.banner_img = await getStorageImageUrl(bannerImgFileRef);
            }

            // KMLファイルアップロード
            if (eventData.road_book_extras) {
                const keys = Object.keys(eventData.road_book_extras);
                for (const key of keys) {
                    if (eventData.road_book_extras[key]["kml"] instanceof File) {
                        const kmlFile = eventData.road_book_extras[key]["kml"] as File;
                        const kmlFileExt = kmlFile.name.split(".").pop();
                        const kmlFileRef = await storage.ref(Firebase.V + 'events').child(eventData.short_id)
                            .child(`${eventData.short_id}_route.${kmlFileExt}`);
                        await kmlFileRef.put(kmlFile);
                        eventData.road_book_extras[key]["kml"] = await getStorageImageUrl(kmlFileRef);
                    }
                }
            }

            // チェックポイントは別Collectionなのでデータを分ける
            const checkPoints = eventData.check_points;
            delete eventData.check_points;

            // 以下は現状固定
            eventData.is_controlled_event = true;
            eventData.is_full_function_restrict_event = false;
            eventData.use_push_notification = true;
            eventData.register_from_web = true;

            // イベント登録
            const eventRef = db.collection(Firebase.V + 'events').doc(eventData.short_id);
            await eventRef.set(eventData);

            // チェックポイントを一旦削除
            const prevCheckPointsRef = await db.collection(Firebase.V + 'events')
                .doc(eventData.short_id).collection("check_points").get();
            for (let i = 0; i < prevCheckPointsRef.size; i++) {
                await prevCheckPointsRef.docs[i].ref.delete();
            }

            // チェックポイント登録
            if (checkPoints) {
                for (let i = 0; i < checkPoints?.length; i++) {
                    const checkPointsRef = db.collection(Firebase.V + 'events').doc(eventData.short_id)
                        .collection("check_points").doc(checkPoints[i].id);
                    await checkPointsRef.set(checkPoints[i]);
                }
            }

            return;
        },

        /**
         * イベント削除
         */
        async deleteEvent(eventId: string): Promise<string | null> {
            // チェックポイント削除
            const prevCheckPointsRef = await db.collection(Firebase.V + 'events')
                .doc(eventId).collection("check_points").get();
            for (let i = 0; i < prevCheckPointsRef.size; i++) {
                await prevCheckPointsRef.docs[i].ref.delete();
            }

            // イベント削除
            await db.collection(Firebase.V + 'events').doc(eventId).delete();

            // TODO: SubCollectionの削除
            // TODO: Storage(Banner, KML)削除
            return null;
        },

        /**
         * イベント参加ユーザー取得
         */
        async retrieveEventRegisteredUser(eventId: string, userId: string): Promise<EventRegisteredUserData | null> {
            const userRef = db.collection(Firebase.V + 'events')
                .doc(eventId).collection('entry_users').doc(userId);
            const userSs = await userRef.get();
            if (userSs.exists) {
                return userSs.data() as EventRegisteredUserData;
            } else {
                return null;
            }
        },

        /**
         * イベント参加ユーザー変更
         */
        async editEventRegisteredUser(eventId: string, userId: string, data: EventRegisteredUserData): Promise<String | null> {
            const userRef = db.collection(Firebase.V + 'events')
                .doc(eventId).collection('entry_users').doc(userId);
            const userSs = await userRef.get();
            if (userSs.exists) {
                await userRef.set(data, {merge: true});
                return null;
            } else {
                return "指定されたユーザーが見つかりませんでした";
            }
        },
    };

}
export default createEventStore2(); // 簡易Singleton

/**
 * ユーザーrole
 */
export const AdminUserRoleValues = ["root", "admin", "staff"] as const;
export type AdminUserRoleType = typeof AdminUserRoleValues[number];

/**
 * イベント基本情報
 */
export type EventBasicData = {
    short_id: string,
    admin_uid: string,
    name: string,
    description: string,
    banner_img: File | string,
    date: string[],
    web_site: string,
    start_point: {
        name: string,
        coords: {
            latitude: number,
            longitude: number,
        }
    },
    categories: EventCategoryData[],
    check_points?: EventCheckPointData[],
    road_book_extras: { [index: string]: { [index: string]: File | string} },
    is_controlled_event: boolean,
    is_full_function_restrict_event: boolean,
    use_push_notification: boolean,
    register_from_web: boolean,     // Webから登録されたというフラグ
};

export type EventCategoryData = {
    id: string,
    name: string,
    group_key: string,
};

export type EventCheckPointData = {
    id: string,
    name: string,
    type: string,
    check_value: string,
    order: number,
    categories: Array<string>,
}

/**
 * イベント登録済みユーザー情報
 */
export type EventRegisteredUserData = {
    name: string,
    number: string,
    mobile_number: string,
    vehicle_model: string,
    // もっとあるけどまず必要な項目のみリストしてる
}

/**
 * Storageへアップロードしたファイルから、Firestoreへ保存するファイル参照URLを生成する
 */
async function getStorageImageUrl(uploadedFileRef: firebase.storage.Reference): Promise<string> {
    return await uploadedFileRef.getDownloadURL();
}
