import * as _ from 'lodash';
import date from 'date-and-time';

export function parseDate(date) {
    // FirestoreのDatetime？
    if (_.isObject(date) && _.has(date, "nanoseconds") && _.has(date, "seconds")) {
        return date.toDate();
    } else {
        return new Date(date);
    }
}

export function formatEventFromToDates(dateList) {
    const YMD = "YYYY年M月D日";
    const MD = "M月D日";
    if (_.isArray(dateList)) {
        let dateStr = '';
        for (let i = 0; i < dateList.length; i++) {
            if (i === 0) {
                dateStr += date.format(new Date(dateList[i]), YMD);
            } else {
                dateStr += ',' + date.format(new Date(dateList[i]), MD);
            }
        }
        return dateStr;
    } else {
        if (dateList) {
            return date.format(new Date(dateList), YMD);
        } else {
            return null;
        }
    }
}

export function formatCheckPointFromToTime(dateListObject) {
    const HM = "HH:mm";
    
    // 引数型チェック
    if (!_.isObject(dateListObject) || (!_.has(dateListObject, "from") && !_.has(dateListObject, "to"))) {
        return null;
    }
    
    const dates = ["", ""];
    if (_.has(dateListObject, "from") && date.isValid(dateListObject["from"])) {
        dates[0] = date.format(parseDate(dateListObject["from"]), HM);
    }
    if (_.has(dateListObject, "to") && date.isValid(dateListObject["to"])) {
        dates[1] = date.format(parseDate(dateListObject["to"]), HM);
    }
    
    return dates.join("〜");
}

export function formatShortDateTime(dateObj) {
    return date.format(dateObj, "MM/DD HH:mm");
}

export function convLatLng(latitudeLongitudeObject) {
    return {
        lat: latitudeLongitudeObject["latitude"],
        lng: latitudeLongitudeObject["longitude"]
    };
}

// 頭ゼロ番代はスタッフとみなす（暫定対応）
export function isStaff(numStr) {
    return /^0+.+$/g.test(numStr);
}

export function isAdmin(authValue) {
    return authValue.userInfo.role === 'root' || authValue.userInfo.role === 'admin';
}
