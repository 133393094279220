import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles, Grid, Typography, Button} from "@material-ui/core";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import * as Styles from "../../../components/admin/Styles";
import EventTitleValueDisplay from "../../../components/admin/EventTitleValueDisplay";
import EventStore from "../../../stores/EventStore";
import * as Utils from "../../../Utils";

export default function EventRegisterCheckPointMainScreen() {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const eventId = params["event_id"];
    
    const [showLoading, setShowLoading] = useState(false);
    const [title, setTitle] = useState('CP受付');
    const [checkPointList, setCheckPointList] = useState([]);
    
    // 初期処理
    useEffect(() => {
        retrieveDisplayData().then(({eventData}) => {
            setTitle(eventData["name"] + ' - CP受付');
        });
        
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // 画面読み込み
    const retrieveDisplayData = async () => {
        try {
            setShowLoading(true);
            
            // イベント情報取得
            const eventData = await EventStore.retrieveEvent(eventId);
            // チェックポイント情報取得
            const checkPointDataList = await EventStore.retrieveEventCheckPoints(eventId);
            setCheckPointList(checkPointDataList);
            
            return {eventData, checkPointDataList};
        } catch (e) {
            console.error(e);
        } finally {
            setShowLoading(false);
        }
    };
    
    // CP受付ボタンクリック
    const onClickCpRegister = (checkPointId) => {
        history.push(`/event/${eventId}/check_point_register/${checkPointId}`);
    };
    
    return (
        <AdminBaseTemplate
            title={title}
            isShowLoadingModal={showLoading}
        >
            <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">CP受付</Typography>
                    {checkPointList.map((checkPoint) => (
                        <EventTitleValueDisplay
                            key={checkPoint["id"]}
                            title={checkPoint["name"]}
                            primaryValue={(
                                <Button
                                    variant="contained" color="primary" className={classes.registerButton}
                                    onClick={() => onClickCpRegister(checkPoint["id"])}
                                >
                                    受付
                                </Button>
                            )}
                            secondaryValue={Utils.formatCheckPointFromToTime(checkPoint["open_window"]) || 'ー'}
                        />
                    ))}
                </Grid>
                
                {/*<Grid item xs={12}>*/}
                {/*    <Typography variant="h6">CP手動OPEN/CLOSE</Typography>*/}
                {/*    <Typography variant="h6">(未実装)</Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </AdminBaseTemplate>
    );
    
};

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    registerButton: {
        width: '100%',
        marginTop: '0.4rem',
        fontSize: "1.25rem",
        fontWeight: "bold",
    }
}));
