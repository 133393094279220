import React, {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Button, Divider, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {Controller, useForm} from "react-hook-form";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import UserStore from "../../../stores/UserStore";
import * as Styles from "../../../components/admin/Styles";

export default function EventPushNotificationScreen() {
    const classes = useStyles();
    const params = useParams();
    const flashMessageContext = useContext(FlashMessageContext);

    const eventId = params["event_id"];

    // 初期処理
    useEffect(() => {
        return () => {
            flashMessageContext.hideMessage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Form設定
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            message: '',
            url: '',
        }
    });

    // 送信時処理
    const onSubmit = async (data) => {
        if (window.confirm(
            '本当にPUSH通知を送信してよろしいですか？\n' +
            `このイベントに登録された全員のスマートフォンにPUSH通知が送信されます`)) {

            // PUSH通知送信
            await UserStore.sendPushNotification(eventId, data.message, data.url);
            flashMessageContext.showMessage("送信しました", "success");
        }
    };

    return (
        <AdminBaseTemplate
            title='PUSH通知送信'
        >
            <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="info">この機能はまだBeta版です</Alert>
                    <Alert severity="warning">PUSH通知はOSの設定や電波状況等、様々な要因により、必ずしも全員に届くとは限りません。ご注意ください。</Alert>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">PUSH通知送信</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            name="message"
                            rules={{
                                required: true,
                                minLength: 6,
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="通知メッセージ"
                                    fullWidth
                                    // required
                                    margin="normal"
                                    helperText="スマートフォンのPUSH通知エリアは狭いので、重要なメッセージを簡潔に記述してください。詳細は以下のWebサイトに記述してください"
                                    error={errors?.message}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="url"
                            rules={{
                                pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="詳細情報URL"
                                    fullWidth
                                    margin="normal"
                                    helperText="[省略可能] 詳細情報が掲載された別サイト(例:Twitter)のURL。入力されなかった場合、スマートフォンのPUSH通知をタップしても何も起きません"
                                    error={errors?.url}
                                />
                            )}
                        />
                        <Divider style={Styles.spacerStyle}/>

                        <Grid container direction="row" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="secondary">PUSH通知送信</Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    listContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));
