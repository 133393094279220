export const container = {
    justifyContent: 'center',
    paddingLeft: '0.3rem',
    paddingRight: '0.3rem',
}

export const textCenter = {
    textAlign: "center"
}

export const textRight = {
    textAlign: "right"
}

export const spacerStyle = {
    backgroundColor: 'transparent',
    marginTop: '0.3rem',
    marginBottom: '0.3rem',
}
