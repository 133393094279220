import React, {useEffect, useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles, Button, Divider, Grid, Typography, Card, CardActionArea, CardContent, CardMedia} from "@material-ui/core";

import * as Firebase from "../../stores/data_sources/Firebase";
import AdminBaseTemplate from "../../templates/AdminBaseTemplate";
import * as Utils from "../../Utils";
import * as Styles from "../../components/admin/Styles";
import EventStore from "../../stores/EventStore";

export default function HomeScreen() {
    const classes = useStyles();
    const history = useHistory();
    const authValue = useContext(Firebase.AuthContext);

    const [eventDataList, setEventDataList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    
    // 初期処理
    useEffect(() => {
        // イベント登録ボタン 表示可否
        if (Utils.isAdmin(authValue)) {
            setIsAdmin(true);
        }

        (async () => {
            try {
                setShowLoading(true);
                const eventDataList = await EventStore.retrieveOwnEvents();
                setEventDataList(eventDataList);
            } catch (error) {
                console.error(error);
            } finally {
                setShowLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // イベント登録クリック
    const onRegisterEventClick = () => {
        history.push('/event/register/new');
    };
    
    // イベントクリック時
    const onEventClick = (eventData) => {
        history.push(`/event/${eventData.id}`);
    }
    
    // ログアウト時
    const onLogoutClick = async () => {
        await Firebase.logout();
        history.push('/login');
    };
    
    return (
        <AdminBaseTemplate
            title="KomaTour管理 - HOME"
            isShowLoadingModal={showLoading}
            rightButton={isAdmin && (
                <Button variant="contained" onClick={onRegisterEventClick}>イベント登録</Button>
            )}
        >
            <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12}>
                    {eventDataList.map(eventData => (
                        <React.Fragment key={eventData.id}>
                            <Card>
                                <CardActionArea onClick={() => onEventClick(eventData)}>
                                    {eventData['banner_img'] ? (
                                        <CardMedia
                                            className={classes.media}
                                            image={eventData['banner_img']}
                                            title="eventData['name']"
                                        />
                                    ) : null}
                                    <CardContent>
                                        <Typography variant="h2">{eventData['name']}</Typography>
                                        {eventData["date"] ? (
                                            <Typography variant="body1" textoverflow="ellipsis">
                                                日時: {Utils.formatEventFromToDates(eventData["date"])}
                                            </Typography>
                                        ) : null}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                            <Divider style={Styles.spacerStyle} />
                        </React.Fragment>
                    ))}
                </Grid>

                <Grid item xs={12} className={classes.center}>
                    <Button variant="contained" color="secondary" onClick={onLogoutClick}>
                        ログアウト
                    </Button>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    center: Styles.textCenter,
    media: {
        height: 0,
        paddingTop: '31.25%', // 16:5,
    }
}));
