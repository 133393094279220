import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Grid, makeStyles, TextField, TextFieldProps} from "@material-ui/core";
import {useForm, SubmitHandler, Controller} from "react-hook-form";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import * as Styles from "../../../components/admin/Styles";
import EventStore2, {EventBasicData, EventCategoryData, EventCheckPointData} from "../../../stores/EventStore2";
import {useHistory, useParams} from "react-router-dom";

type Params = {
    event_id?: string,
};

export default function EventRegisterScreen() {
    const { event_id } = useParams<Params>();
    const classes = useStyles();
    const history = useHistory();
    const flashMessageContext = useContext(FlashMessageContext);

    const bannerImgInputRef = useRef<HTMLInputElement>(null);
    const kmlFileInputRef = useRef<HTMLInputElement>(null);

    const [savedEventId, setSavedEventId] = useState<string>("");
    const [isExistingData, setIsExistingData] = useState<boolean>(false);
    const [savedBannerImgUrl, setSavedBannerImgUrl] = useState<string>("");
    const [savedKmlFileUrl, setSavedKmlFileUrl] = useState<string>("");

    // Form設定
    const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            id: '',
            name: '',
            description: '',
            banner_img: '',
            start_date: '',
            end_date: '',
            web_site: '',
            start_point_name: '',
            start_point_lat: 0,
            start_point_lng: 0,
            kml_file: '',
            categories: ['', '', ''],
            check_points: [
                ['', '', ''],
                ['', '', ''],
                ['', '', ''],
            ]
        },
        // mode: "onBlur",  // ファイルを選択してもエラーになる...
    });

    // 初期処理
    useEffect(() => {
        if (event_id) {
            // 既存データ読み込み
            EventStore2.retrieveEvent(event_id).then((data) => {
                if (!data) {
                    return;
                }

                // Web生成データ以外は編集できないようにする
                if (!data.register_from_web) {
                    flashMessageContext.showMessage("システム管理者により作成されたデータは変更できません", "error");
                    return;
                }

                // データ詰め替え
                const formData = {} as FormData;

                formData.id = data.short_id;
                formData.name = data.name;
                formData.description = data.description;
                if (data.date && data.date.length > 0) formData.start_date = data.date[0];
                if (data.date && data.date.length > 1) formData.end_date = data.date[1];
                formData.web_site = data.web_site;
                formData.start_point_name = data.start_point.name;
                formData.start_point_lat = data.start_point.coords.latitude;
                formData.start_point_lng = data.start_point.coords.longitude;
                formData.categories = [];
                for (const category of data.categories) {
                    formData.categories.push(category.name);
                }

                // ファイル系はFirebase.StorageのURLが入っているため、そのまま設定するとエラーになってしまう
                if (data.banner_img && typeof data.banner_img === 'string') {
                    setSavedBannerImgUrl(data.banner_img as string);
                }

                // KMLファイルの保存
                if (data.road_book_extras &&
                    data.road_book_extras[formData.id + "_group_all"] &&
                    data.road_book_extras[formData.id + "_group_all"]["kml"] &&
                    typeof data.road_book_extras[formData.id + "_group_all"]["kml"] === 'string') {
                    setSavedKmlFileUrl(data.road_book_extras[formData.id + "_group_all"]["kml"] as string);
                }

                // チェックポイント
                formData.check_points = [
                    ['', '', ''],
                    ['', '', ''],
                    ['', '', ''],
                ];
                if (data.check_points) {
                    for (const checkPoint of data.check_points) {
                        // `${formData.id}_cp_${i}_${j}`
                        const reg = new RegExp("^" + formData.id + "_cp_(\\d)+_(\\d)+$");
                        const match = reg.exec(checkPoint.id);
                        if (match && match.length === 3) {
                            const i = parseInt(match[1], 10);
                            const j = parseInt(match[2], 10);
                            formData.check_points[i][j] = checkPoint.name;
                        }
                    }
                }

                // 取得した既存データをFormへセット
                reset(formData);
                setIsExistingData(true);
                setSavedEventId(data.short_id);
            })
        }

        return () => {
            flashMessageContext.hideMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Submit時処理
    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        try {
            // データ詰め替え
            const data = {} as EventBasicData;

            data.short_id = formData.id;
            data.name = formData.name;
            data.description = formData.description;
            data.date = [];
            if (formData.start_date) data.date.push(formData.start_date);
            if (formData.end_date) data.date.push(formData.end_date);
            data.web_site = formData.web_site;
            data.start_point = {
                name: formData.start_point_name,
                coords: {
                    latitude: Number(formData.start_point_lat),
                    longitude: Number(formData.start_point_lng),
                }
            };

            // Banner画像ファイル取得
            if (formData.banner_img) {
                data.banner_img = bannerImgInputRef.current!.files![0];
            } else if (savedBannerImgUrl) {
                data.banner_img = savedBannerImgUrl;
            }

            // KMLファイル取得
            if (formData.kml_file) {
                data.road_book_extras = {};
                data.road_book_extras[formData.id + "_group_all"] = {
                    kml: kmlFileInputRef.current!.files![0]
                }
            } else if (savedKmlFileUrl) {
                data.road_book_extras = {};
                data.road_book_extras[formData.id + "_group_all"] = {
                    kml: savedKmlFileUrl
                }
            }

            // カテゴリデータ補完
            const categories: EventCategoryData[] = [];
            for (let i = 0; i < 3; i++) {
                if (formData.categories.length > i && formData.categories[i]) {
                    categories.push({
                        id: formData.id + "_cat_" + (i + 1),
                        name: formData.categories[i],
                        group_key: formData.id + "_group_all",
                    })
                }
            }
            data.categories = categories;

            // チェックポイントデータ補完
            const checkPoints: EventCheckPointData[] = [];
            for (let i = 0; i < categories.length; i++) {
                for (let j = 0; j < 3; j++) {
                    if (formData.check_points[i][j]) {
                        checkPoints.push({
                            id: `${formData.id}_cp_${i}_${j}`,
                            name: formData.check_points[i][j],
                            type: 'QR',
                            check_value: `${formData.id}_cp_${i}_${j}_check_value`,
                            order: i * 10 + j,
                            categories: [categories[i].id],
                        })
                    }
                }
            }
            data.check_points = checkPoints;

            // データ登録
            const errorMessage = await EventStore2.registerEvent(data, !isExistingData);
            if (errorMessage) {
                flashMessageContext.showMessage(`イベント登録でエラーが発生しました。${errorMessage}`, "error");
                return;
            }

            flashMessageContext.showMessage("イベントを登録しました");
        } catch (e) {
            flashMessageContext.showMessage("イベント登録で異常が発生しました", "error");
            console.error(e);
        }
    }

    // 削除ボタン押下時
    const onDelete = async () => {
        if (window.confirm(
            '本当にこのイベントを削除してよろしいですか？\n' +
            `この操作は取り消すことができません`)) {
            const errorMessage = await EventStore2.deleteEvent(savedEventId);
            if (errorMessage) {
                flashMessageContext.showMessage(`イベント削除でエラーが発生しました。${errorMessage}`, "error");
                return;
            }
            flashMessageContext.showMessage("イベントを削除しました");
            history.push(`/`);
        }
    };

    return (
        <AdminBaseTemplate
            title='イベント登録'
            rightButton={null}
            isShowLoadingModal={false}
        >
            <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12} sm={10}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* ID（short_idと共通にする） */}
                        <Controller
                            control={control}
                            name="id"
                            rules={{
                                required: { value: true, message: "必須入力です" },
                                minLength: { value: 6, message: "イベントIDは6桁以上で入力してください" },
                                pattern: { value: /^[a-zA-Z0-9_-]+$/, message: "イベントIDは英数字と-_のみで入力してください" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="イベントID*"
                                    helperText={!!errors.id ? errors.id?.message : "参加ユーザーがkoma-tourアプリ上で入力するイベントID（一度決めると変更できません）"}
                                    error={!!errors.id}
                                    disabled={isExistingData}

                                    autoComplete="off"
                                    InputLabelProps={{shrink:true}}
                                    margin="normal"
                                    fullWidth={true}
                                />
                            )}
                        />

                        {/* イベント名 */}
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: { value: true, message: "必須入力です" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="イベント名*"
                                    helperText={!!errors.name ? errors.name?.message : "表示されるイベント名称"}
                                    error={!!errors.name}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        {/* イベント補足 */}
                        <Controller
                            control={control}
                            name="description"
                            rules={{}}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="イベント説明"
                                    helperText={!!errors.description ? errors.description?.message : "イベント名の補足、詳細説明など"}
                                    error={!!errors.description}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        {/* バナー画像 */}
                        <Controller
                            control={control}
                            name="banner_img"
                            rules={{
                                required: { value: !savedBannerImgUrl, message: "バナー画像ファイルは必須です" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="file"
                                    label="バナー画像ファイル"
                                    inputProps={{
                                        accept: "image/*"
                                    }}
                                    inputRef={bannerImgInputRef}
                                    helperText={!!errors.banner_img ? errors.banner_img?.message : "イベントトップに表示される画像ファイル。900*300px程度の画像を準備してください"}
                                    error={!!errors.banner_img}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />
                        {!!savedBannerImgUrl && (
                            <img src={savedBannerImgUrl} className={classes.bannerImg} alt="" />
                        )}

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {/* イベント開始日 */}
                                <Controller
                                    control={control}
                                    name="start_date"
                                    rules={{
                                        pattern: { value: /^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/, message: "イベント開始日はYYYY/MM/DD形式で入力してください" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="イベント開始日"
                                            helperText={!!errors.start_date ? errors.start_date?.message : "イベントの開始日をYYYY/MM/DD形式で設定（1Dayイベントの場合はこの値のみ設定）"}
                                            error={!!errors.start_date}

                                            {...commonTextFieldProps}
                                        />
                                    )}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                {/* イベント終了日 */}
                                <Controller
                                    control={control}
                                    name="end_date"
                                    rules={{
                                        pattern: { value: /^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/, message: "イベント終了日はYYYY/MM/DD形式で入力してください" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="イベント終了日"
                                            helperText={!!errors.end_date ? errors.end_date?.message : "イベントの終了日をYYYY/MM/DD形式で設定"}
                                            error={!!errors.end_date}

                                            {...commonTextFieldProps}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        {/* Webサイトアドレス */}
                        <Controller
                            control={control}
                            name="web_site"
                            rules={{
                                pattern: { value: /^(http|https):\/\/.+/, message: "URLを正しい形式で入力してください" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="WebサイトURL"
                                    helperText={!!errors.web_site ? errors.web_site?.message : "イベント内容が記載されたWebサイトURL"}
                                    error={!!errors.web_site}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {/* 集合場所 */}
                                <Controller
                                    control={control}
                                    name="start_point_name"
                                    rules={{
                                        required: { value: true, message: "必須入力です" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="集合場所（名称）*"
                                            helperText={!!errors.start_point_name ? errors.start_point_name?.message : "集合場所/スタート地点の名称を入力"}
                                            error={!!errors.start_point_name}

                                            {...commonTextFieldProps}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {/* 集合場所座標（緯度） */}
                                <Controller
                                    control={control}
                                    name="start_point_lat"
                                    rules={{
                                        required: { value: true, message: "必須入力です" },
                                        pattern: { value: /^-?\d+(\.\d+)?$/, message: "座標は数値形式で入力してください" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="集合場所（緯度）*"
                                            helperText={!!errors.start_point_lat ? errors.start_point_lat?.message : "集合場所/スタート地点の緯度（例: 35.185012）"}
                                            error={!!errors.start_point_lat}

                                            {...commonTextFieldProps}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {/* 集合場所座標（経度） */}
                                <Controller
                                    control={control}
                                    name="start_point_lng"
                                    rules={{
                                        required: { value: true, message: "必須入力です" },
                                        pattern: { value: /^-?\d+(\.\d+)?$/, message: "座標は数値形式で入力してください" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="集合場所（経度）*"
                                            helperText={!!errors.start_point_lng ? errors.start_point_lng?.message : "集合場所/スタート地点の緯度（例: 136.899752）"}
                                            error={!!errors.start_point_lng}

                                            {...commonTextFieldProps}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        {/* KMLファイル */}
                        <Controller
                            control={control}
                            name="kml_file"
                            rules={{}}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="file"
                                    label="ルートKMLァイル"
                                    inputProps={{
                                        accept: ".kml"
                                    }}
                                    inputRef={kmlFileInputRef}
                                    helperText={!!errors.kml_file ? errors.kml_file?.message : "ルートが示されたKMLファイル"}
                                    error={!!errors.kml_file}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />
                        {!!savedKmlFileUrl && (
                            <span>指定済み：{savedKmlFileUrl}</span>
                        )}

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* カテゴリ（1日目） */}
                                {/* この画面では、1日目, 2日目, 3日目 という使い方にのみフォーカスしている。そのうち何とかしたいね */}
                                <Controller
                                    control={control}
                                    name="categories.0"
                                    rules={{
                                        required: { value: true, message: "必須入力です" },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="カテゴリ名(1日目)*"
                                            helperText={!!errors.categories?.[0] ? errors.categories?.[0]?.message : "カテゴリ(1日目)名称。迷ったらイベント名と同一でOK"}
                                            error={!!errors.categories?.[0]}

                                            {...commonTextFieldProps}
                                            fullWidth={false}
                                        />
                                    )}
                                />
                            </Grid>
                            {[0, 1, 2].map(index => (
                                <Grid item xs={4} key={`cp-0-${index}`}>
                                    {/* CP（1日目）-N */}
                                    <Controller
                                        control={control}
                                        name={`check_points.0.${index}`}
                                        rules={{}}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label={`CP名(1日目)-${(index + 1)}`}
                                                helperText={`CP(1日目)-${(index + 1)}の名称。不要の場合は空白`}

                                                {...commonTextFieldProps}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* カテゴリ（2日目） */}
                                <Controller
                                    control={control}
                                    name="categories.1"
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="カテゴリ名(2日目)"
                                            helperText={!!errors.categories?.[1] ? errors.categories?.[1]?.message : "カテゴリ(2日目)名称。不要の場合は空白"}
                                            error={!!errors.categories?.[1]}

                                            {...commonTextFieldProps}
                                            fullWidth={false}
                                        />
                                    )}
                                />
                            </Grid>
                            {[0, 1, 2].map(index => (
                                <Grid item xs={4} key={`cp-1-${index}`}>
                                    {/* CP（2日目）-N */}
                                    <Controller
                                        control={control}
                                        name={`check_points.1.${index}`}
                                        rules={{}}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label={`CP名(2日目)-${(index + 1)}`}
                                                helperText={`CP(2日目)-${(index + 1)}の名称。不要の場合は空白`}

                                                {...commonTextFieldProps}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* カテゴリ（3日目） */}
                                <Controller
                                    control={control}
                                    name="categories.2"
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="カテゴリ名(3日目)"
                                            helperText={!!errors.categories?.[2] ? errors.categories?.[2]?.message : "カテゴリ(3日目)名称。不要の場合は空白"}
                                            error={!!errors.categories?.[2]}

                                            {...commonTextFieldProps}
                                            fullWidth={false}
                                        />
                                    )}
                                />
                            </Grid>
                            {[0, 1, 2].map(index => (
                                <Grid item xs={4} key={`cp-2-${index}`}>
                                    {/* CP（3日目）-N */}
                                    <Controller
                                        control={control}
                                        name={`check_points.2.${index}`}
                                        rules={{}}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label={`CP名(3日目)-${(index + 1)}`}
                                                helperText={`CP(23目)-${(index + 1)}の名称。不要の場合は空白`}

                                                {...commonTextFieldProps}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container direction="row" justifyContent={isExistingData ? "space-between": "flex-end"}>
                            {isExistingData && (
                                <Button variant="outlined" onClick={onDelete}>イベントの削除</Button>
                            )}
                            <Button type="submit" variant="contained" color="secondary" size="large">登録</Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    bannerImg: {
        width: "150px",
        display: "block",
    }
}));

type FormData = {
    id: string,
    name: string,
    description: string,
    banner_img: string,
    start_date: string,
    end_date: string,
    web_site: string,
    start_point_name: string,
    start_point_lat: number,
    start_point_lng: number,
    categories: Array<string>,
    check_points: Array<Array<string>>,
    kml_file: string,
};

const commonTextFieldProps: TextFieldProps = {
    autoComplete: "off",
    InputLabelProps: { shrink: true },
    margin: "normal",
    fullWidth: true,
}
