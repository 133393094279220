import React, {useContext, useEffect, useState} from "react";
import {Grid, Typography, makeStyles, Paper, Button, Divider} from "@material-ui/core";
import QRCode from 'react-qr-code';

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import * as Styles from "../../../components/admin/Styles";
import EventStore2, {EventBasicData, EventCheckPointData} from "../../../stores/EventStore2";
import {useParams} from "react-router-dom";

type Params = {
    event_id?: string,
};

export default function EventQrDisplayScreen() {
    const { event_id } = useParams<Params>();
    const classes = useStyles();
    const flashMessageContext = useContext(FlashMessageContext);

    const [eventData, setEventData] = useState<EventBasicData | null>(null);
    const [checkPoints, setCheckPoints] = useState<Array<EventCheckPointData>>([]);

    // 初期処理
    useEffect(() => {
        if (event_id) {
            // 既存データ読み込み
            EventStore2.retrieveEvent(event_id).then((data) => {
                if (!data) {
                    return;
                }
                setEventData(data);

                // CPデータ取得
                if (data.check_points) {
                    setCheckPoints(data.check_points);
                }
            })
        }

        return () => {
            flashMessageContext.hideMessage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminBaseTemplate
            title={`イベントQR表示 - ${eventData?.name}`}
            rightButton={null}
            isShowLoadingModal={false}
        >
            <Grid container className={classes.container} spacing={2} style={{marginBottom:'1.5rem'}}>
                <QrCodeComponent
                    id="qr_id_reception"
                    eventName={eventData?.name}
                    cpName="受付"
                    value={event_id!}
                />
            </Grid>
            <hr />
            <Grid container className={classes.container} spacing={2} style={{marginBottom:'1.5rem'}}>
                {checkPoints.map((checkPoint, index) => (
                    <QrCodeComponent
                        key={`qr_id_cp_${index}`}
                        id={`qr_id_cp_${index}`}
                        eventName={eventData?.name}
                        cpName={checkPoint.name}
                        value={checkPoint.check_value}
                    />
                ))}
            </Grid>
            <hr />
            <Grid container className={classes.container} spacing={2} style={{marginBottom:'1.5rem'}}>
                <QrCodeComponent
                    id="qr_id_registration"
                    eventName={eventData?.name}
                    cpName="イベント登録"
                    value={eventData?.short_id}
                />
            </Grid>
        </AdminBaseTemplate>
    );
}

type QrCodeComponentProps = {
    id: string,
    eventName?: string,
    cpName?: string,
    value?: string,
};

const QrCodeComponent = (props: QrCodeComponentProps) => {
    return (
        <Grid item style={{textAlign:'center'}}>
            <Paper style={{padding:"1.5rem"}}>
                <Typography variant="h2" style={{textAlign:'center',marginBottom:'1rem'}}>
                    {props.eventName}<br/>{props.cpName}
                </Typography>
                <QRCode
                    id={props.id}
                    value={props.value || ""}
                />
            </Paper>
            <Button
                onClick={() => {
                    onDownloadClick(props.id, `${props.eventName}_${props.cpName}`);
                }}
                variant="contained"
                color="secondary"
                style={{marginTop:'0.5rem'}}
            >
                保存
            </Button>
        </Grid>
    );
};

// QRダウンロード
const onDownloadClick = (id: string, fileName: string) => {
    const svg = document.getElementById(id);
    if (!svg) {
        return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = fileName;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
}));
