import React, {useRef, useEffect, useState, useCallback, useContext} from "react";
import {
    makeStyles,
    Grid,
    Box,
    Typography,
    Snackbar,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Icon
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import MyLocation from '@material-ui/icons/MyLocation';
import GoogleMapReact from 'google-map-react';
import date from 'date-and-time';
import clsx from 'clsx';
import axios from "axios";
import {kml} from "@tmcw/togeojson";
import * as Utils from '../../Utils';
import * as Firebase from "../../stores/data_sources/Firebase";
import EventStore from "../../stores/EventStore";

export default function EventUserLocationMap(
    {eventId, initialCenter, initialZoom, kmlFileList, userDataList}) {
    const authValue = useContext(Firebase.AuthContext);

    const googleMap = useRef(null);
    const googleMaps = useRef(null);
    const currentLocationIcon = useRef(null);

    const [isAdmin, setIsAdmin] = useState(false);
    const [kmlDataList, setKmlDataList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [latestLat, setLatestLat] = useState(0);
    const [latestLng, setLatestLng] = useState(0);
    const [isMapLoaded, setIsMaLoaded] = useState(false);
    const [filterNumber, setFilterNumber] = useState('');
    const [usersLocationHidden, setUsersLocationHidden] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        if (Utils.isAdmin(authValue)) {
            setIsAdmin(true);
        }
        EventStore.retrieveEventUserLocationHiddenFlag(eventId).then((isHidden) => {
            setUsersLocationHidden(isHidden);
        });
    }, [eventId, authValue, userDataList]);

    // KMLファイル読み込み処理
    useEffect(() => {
        // そのうち指定されたカテゴリだけのKMLを表示するかもしれないけど、今は全KMLをルート表示する
        const kmlDownloadFnList = [];
        for (const kmlFile of Object.values(kmlFileList)) {
            kmlDownloadFnList.push(axios.get(kmlFile));
        }

        Promise.all(kmlDownloadFnList).then((results) => {
            const kmls = [];
            for (const result of results) {
                kmls.push(kml(new DOMParser().parseFromString(result.data, "text/xml")));
            }
            setKmlDataList(kmls);
        });
    }, [kmlFileList]);

    // KMLデータ, Google Map読み込み時処理
    useEffect(() => {
        if (!kmlDataList || kmlDataList.length === 0 || !googleMaps.current || !googleMap.current) {
            return;
        }

        let polyline = null;
        for (const kmlData of kmlDataList) {
            for (const feature of kmlData['features']) {
                if (feature['type'] === 'Feature' && feature['geometry']?.['type'] === 'LineString') {
                    const coordinates = [];
                    for (const coordinate of feature['geometry']?.['coordinates']) {
                        coordinates.push({
                            lat: coordinate[1],
                            lng: coordinate[0],
                        });
                    }

                    polyline = new googleMaps.current.Polyline({
                        path: coordinates,
                        geodesic: true,
                        strokeColor: feature['properties']?.['stroke'] ?? '#FF0000',
                        strokeOpacity: 0.5,
                        strokeWeight: 4,
                    });
                    polyline.setMap(googleMap.current);
                }
            }
        }

        return () => {
            if (polyline) polyline.setMap(null);
        };
    }, [kmlDataList, isMapLoaded]);

    // ゼッケンFilterテキスト変更時
    const onChangeNumberFilterText = useCallback((e) => {
        const inputValue = (e.target.value ?? '').trim();
        setFilterNumber(inputValue);
    }, []);

    // マーカークリック時 > Toast Open
    const onMarkerClick = useCallback((number, name, vehicleModel, msgLastReportTime, lat, lng) => {
        setLatestLat(lat);
        setLatestLng(lng);

        setToastMessage(`${number ?? ''}:${name}-${vehicleModel} (最新通知:${msgLastReportTime})`);
        setToastOpen(true);
    }, []);

    // Toast Close時
    const onToastClose = useCallback((event, reason) => {
        setToastOpen(false);
    }, []);

    // Toast Map タップ時
    const onMapClick = useCallback(() => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${latestLat},${latestLng}`,
            '_blank', 'noopener,noreferrer');
    }, [latestLat, latestLng]);

    // ユーザー現在位置表示/非表示ボタン
    const onUserLocationHiddenButtonClick = useCallback(async () => {
        await EventStore.setEventUserLocationHiddenFlag(eventId, !usersLocationHidden);

        // 画面全体リロード
        window.location.reload();
    }, [eventId, usersLocationHidden]);

    // 現在値取得ボタンクリック
    const onCurrentLocationClick = useCallback(() => {
        if (!googleMaps.current || !googleMap.current) {
            return;
        }
        if (currentLocationIcon.current) {
            currentLocationIcon.current.setMap(null);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                currentLocationIcon.current = new googleMaps.current.Marker({
                    icon: {
                        fillColor: "#00F",
                        fillOpacity: 1,
                        path: googleMaps.current.SymbolPath.CIRCLE,
                        scale: 8,
                        strokeColor: "#FFF",
                        strokeWeight: 2,
                    },
                    position: pos,
                    title: '現在地'
                });
                currentLocationIcon.current.setMap(googleMap.current);
                googleMap.current.setCenter(pos);
            });
        }
    }, []);

    // Toast Action
    const toastAction = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={onMapClick}>
                MAP
            </Button>
        </React.Fragment>
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            全参加者位置
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={onCurrentLocationClick}
                                >
                                    <MyLocation/>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <TextField
                                        variant="standard"
                                        label="ゼッケンFilter"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={onChangeNumberFilterText}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {usersLocationHidden ? (
                    <Box className={classes.mapContainerHidden} display='flex' justifyContent='center'
                         alignItems='center'>
                        <Typography variant="h3" display="block">イベント管理者により非表示とされました</Typography>
                    </Box>
                ) : (
                    <Box className={classes.mapContainer}>
                        <Typography variant="caption" display="block" gutterBottom style={{color: USER_ACTIVE_COLOR}}>
                            ピンク/オレンジMarkerは15分以内の位置情報
                        </Typography>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: Firebase.GOOGLE_KEY}}
                            center={initialCenter || {lat: 35.18564543683397, lng: 136.89958150056324}}
                            defaultZoom={initialZoom || 12}
                            options={{
                                overviewMapControl: true,
                                mapTypeControl: true,
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => {
                                googleMap.current = map;
                                googleMaps.current = maps;
                                setIsMaLoaded(true);
                            }}
                        >
                            {userDataList.map(userData => !filterNumber || filterNumber === userData["number"] ? (
                                <UserMarker
                                    key={userData['id']}
                                    lat={userData["current_location"] ? userData["current_location"]["latitude"] : null}
                                    lng={userData["current_location"] ? userData["current_location"]["longitude"] : null}
                                    timeStamp={userData["current_location"] ? userData["current_location"]["timestamp"] : null}
                                    number={userData["number"]}
                                    name={userData["name"]}
                                    vehicleModel={userData["vehicle_model"]}
                                    onClickHandler={onMarkerClick}
                                />
                            ) : null)}
                        </GoogleMapReact>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={toastOpen}
                            onClose={onToastClose}
                            autoHideDuration={2000}
                            action={toastAction}
                            message={toastMessage}
                        />
                        {isAdmin &&
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Button variant="contained" onClick={onUserLocationHiddenButtonClick}>
                                    {usersLocationHidden ? "表示" : "非表示に"}する(全管理画面利用者に適用)
                                </Button>
                            </Grid>
                        }
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}


function UserMarker({lat, lng, timeStamp, number, name, vehicleModel, onClickHandler}) {
    const classes = useStyles();

    // 位置情報記録日時が15分以上前ならスタイル変更
    let isOld = true;
    if (timeStamp) {
        const now = new Date();
        const dt = Utils.parseDate(timeStamp);
        if (now <= date.addMinutes(dt, 15)) {
            isOld = false;
        }
    }

    // スタッフかどうか？
    let isStaff = false;
    if (Utils.isStaff(number)) {
        isStaff = true;
    }

    // ゼッケン番号がなければ名前の最初のN文字
    let displayName = number;
    if (!number) {
        displayName = String(name).substring(0, 1);
    }

    if (!!lat && !!lng) {
        const onClick = () => {
            let lastReportTime = "--";
            if (timeStamp) {
                lastReportTime = Utils.formatShortDateTime(Utils.parseDate(timeStamp));
            }
            onClickHandler(number, name, vehicleModel, lastReportTime, lat, lng);
        };

        if (!isStaff) {
            return (
                <div className={clsx(classes.markerUser, isOld && classes.markerUserOld)} onClick={onClick}>
                    {displayName}
                </div>
            );
        } else {
            return (
                <div className={clsx(classes.markerStaff, isOld && classes.markerStaffOld)} onClick={onClick}>
                    {displayName}
                </div>
            );
        }
    } else {
        return null
    }
}

const MARKER_SIZE_UNIT = "rem"

const USER_MARKER_SIZE_NUMBER = 2.2;
const USER_MARKER_SIZE = USER_MARKER_SIZE_NUMBER + MARKER_SIZE_UNIT;
const STAFF_MARKER_SIZE_NUMBER = 1.8;
const STAFF_MARKER_SIZE = STAFF_MARKER_SIZE_NUMBER + MARKER_SIZE_UNIT;

const USER_ACTIVE_COLOR = '#FF00FF';
const USER_OLD_COLOR = '#777';
const STAFF_ACTIVE_COLOR = '#ffaa66';
const STAFF_OLD_COLOR = '#999';

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        backgroundColor: 'lightgray',
        height: '75vh',
    },
    mapContainerHidden: {
        backgroundColor: 'lightgray',
        height: '25vh',
        color: '#666'
    },
    markerUser: {
        position: 'absolute',
        width: USER_MARKER_SIZE,
        height: USER_MARKER_SIZE,
        left: (USER_MARKER_SIZE_NUMBER / 2 * -1) + MARKER_SIZE_UNIT,
        top: (USER_MARKER_SIZE_NUMBER / 2 * -1) + MARKER_SIZE_UNIT,
        borderRadius: USER_MARKER_SIZE,
        backgroundColor: USER_ACTIVE_COLOR,

        color: '#ffffff',
        textAlign: 'center',
        lineHeight: USER_MARKER_SIZE,
        fontSize: "1.2rem",
        fontWeight: 'bolder',
    },
    markerUserOld: {
        backgroundColor: USER_OLD_COLOR,
    },

    markerStaff: {
        position: 'absolute',
        width: STAFF_MARKER_SIZE,
        height: STAFF_MARKER_SIZE,
        left: (STAFF_MARKER_SIZE_NUMBER / 2 * -1) + MARKER_SIZE_UNIT,
        top: (STAFF_MARKER_SIZE_NUMBER / 2 * -1) + MARKER_SIZE_UNIT,
        backgroundColor: STAFF_ACTIVE_COLOR,

        color: '#ffffff',
        textAlign: 'center',
        lineHeight: STAFF_MARKER_SIZE,
        fontSize: "0.8rem",
        fontWeight: 'bold',
    },
    markerStaffOld: {
        backgroundColor: STAFF_OLD_COLOR,
    }
}));
