import {Button, Grid} from "@material-ui/core";

export default function DescribedButton(props) {
    return (
        <Button
            variant="contained"
            color="primary"
            focusRipple
            style={{width:'100%'}}
            {...props}
        >
            <Grid container direction="column" alignItems="flex-start" justifyContent="center">
                <Grid item xs={12} style={{textAlign:'left'}}>
                    <h2 style={{margin:0, marginTop:'0.1rem'}}>{props.title}</h2>
                    <p style={{margin:'0',lineHeight:'1rem', marginBottom:'0.1rem'}}>{props.description}</p>
                </Grid>
            </Grid>
        </Button>
    );
}
