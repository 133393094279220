import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React, {createContext, useEffect, useState} from 'react'

// Google API Key（別のところに動かしたほうがいいね）
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

// Firestoreデータバージョン
export const V = 'v1_';

// Firebase setup
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
firebase.initializeApp(firebaseConfig);

// Field name for document ID
export const DOCUMENT_ID = firebase.firestore.FieldPath.documentId();

// Firebase Instance
export const instance = firebase;

// ログインユーザー情報取得
export async function retrieveCurrentUser() {
    const userInfo = await instance.auth().currentUser.getIdTokenResult();
    if (userInfo) {
        return {
            user_id: userInfo.claims.user_id,
            role: userInfo.claims.role,
            admin_uid_list: userInfo.claims.admin_uid_list,
        }
    } else {
        return null;
    }
}

// Logout
export async function logout() {
    await instance.auth().signOut();
}

// Routing用 Context
export const AuthContext = createContext({userPresent: false, user: null, userInfo: null})
export function AuthContextProvider(props) {
    const [state, changeState] = useState({
        userDataPresent: false,
        user: null,
        userInfo: null,
        listener: null
    })
    
    useEffect(() => {
        if (state.listener == null) {
            changeState({
                ...state,
                listener: instance.auth().onAuthStateChanged((user) => {
                    if (user) {
                        retrieveCurrentUser().then(userInfo => {
                            changeState(oldState =>
                                ({...oldState, userDataPresent: true, user, userInfo}));
                        })
                    } else {
                        changeState(oldState =>
                            ({...oldState, userDataPresent: true, user: null, userInfo: null}));
                    }
                })
            });
        }
        return () => {
            if (state.listener) {
                state.listener()
            }
        }
    }, [state])
    
    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
}
