const dealerDataBase = [
    // [OFF]from, to, individual, [ON]from, to, individual, dealer
    [1,23,[300,301],1,2,[300,301],"M.S.C.HARA"],
    [24,32,[302],null,null,[302],"Motorrad 埼玉シティ"],
    [33,51,[303,304],3,4,[303,304],"Motorrad 世田谷"],
    [52,59,[305],null,null,[305],"Motorrad 柏"],
    [60,64,[306],null,null,[5,306],"Motorrad 杉並"],
    [65,66,[307],null,null,[307],"Motorrad 港北"],
    [null,null,[308],null,null,[6,308],"Motorrad 川越"],
    [67,69,[309],null,null,[309],"Motorrad 東京ベイ"],
    [70,89,[193,200,201,310],7,11,[310],"Motorrad 羽田"],
    [90,109,[311,312],12,20,[311,312],"A-big Motorrad"],
    [110,118,[313,314,330],21,31,[313,314,330],"Motorrad 横浜"],
    [119,123,[315],null,null,[32,315],"Motorrad 湘南台"],
    [124,129,[316],null,null,[316],"Motorrad 相模原"],
    [130,132,[317],null,null,[33,317],"Motorrad 筑波"],
    [133,134,[318],null,null,[318],"Motorrad 水戸"],
    [135,136,[319],null,null,[319],"Motorrad 宇都宮"],
    [137,145,[320],null,null,[320],"モトパーク"],
    [146,150,[321,322],null,null,[321,322],"Motorrad 高崎"],
    [151,152,null,null,null,[152],"Motorrad 長野"],
    [153,154,null,null,null,[154],"Motorrad 長野シティ"],
    [155,159,null,null,null,[155],"Motorrad 豊田"],
    [160,171,[323,324],34,35,[323,324],"Motorrad アルファ"],
    [172,175,[325],null,null,[325],"DATZ名古屋南"],
    [176,177,null,null,null,null,"モトアートササキ"],
    [178,190,[326],null,null,[326],"Motorrad 鈴鹿"],
    [191,192,[327],null,null,[327],"Motorrad カスノ"]
];

function gsDaysGenerateNumberDealer(isOff = true) {
    let fromIdx = 0;
    let toIdx = 1;
    let individualIdx = 2;
    if (!isOff) {
        fromIdx = 3;
        toIdx = 4;
        individualIdx = 5
    }

    const numbers = {};
    for (let i = 0; i < dealerDataBase.length; i++) {
        const dealerData = dealerDataBase[i];
        if (dealerData[fromIdx] && dealerData[toIdx]) {
            for (let j = dealerData[fromIdx]; j <= dealerData[toIdx]; j++) {
                numbers["" + j] = dealerData[6];
            }
        }
        if (dealerData[individualIdx]) {
            dealerData[individualIdx].forEach((num) => {
                numbers["" + num] = dealerData[6];
            });
        }
    }
    return numbers;
}
export const gsDaysNumberDealerOff = gsDaysGenerateNumberDealer(true);
export const gsDaysNumberDealerOn = gsDaysGenerateNumberDealer(false);
