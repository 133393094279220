import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    makeStyles,
    Divider,
    Grid,
    Typography,
    Link,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Button
} from "@material-ui/core";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import EventStore from "../../../stores/EventStore";
import * as Utils from "../../../Utils";
import * as Styles from "../../../components/admin/Styles";
import DescribedButton from "../../../components/admin/DescribedButton";
import * as Firebase from "../../../stores/data_sources/Firebase";

export default function EventHomeScreen() {
    const params = useParams();
    const history = useHistory();
    const classes = useStyles();
    const authValue = useContext(Firebase.AuthContext);
    
    const [eventData, setEventData] = useState({});
    const [showLoading, setShowLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    
    const eventId = params["event_id"];
    
    // 初期処理
    useEffect(() => {
        // イベント登録ボタン 表示可否
        if (Utils.isAdmin(authValue)) {
            setIsAdmin(true);
        }

        (async () => {
            try {
                setShowLoading(true);
                
                // イベント情報検索
                setEventData(await EventStore.retrieveEvent(eventId));
            } catch (error) {
                console.error(error);
            } finally {
                setShowLoading(false);
            }
        })();
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDisplayQrClick = () => {
        history.push(`/event/qr/show/${eventId}`);
    };

    const onEditEventClick = () => {
        history.push(`/event/register/update/${eventId}`);
    };
    
    return (
        <AdminBaseTemplate
            title="イベント管理"
            isShowLoadingModal={showLoading}
            rightButton={(
                <>
                    <Button variant="contained" onClick={onDisplayQrClick} style={{marginRight:'1rem'}}>QR表示</Button>
                    {isAdmin && (
                        <Button variant="contained" onClick={onEditEventClick}>イベント変更</Button>
                    )}
                </>

            )}
        >
            <Grid container className={classes.container} spacing={2}>
                {/* イベント基本情報 */}
                <Grid item xs={12}>
                    <Card>
                        {eventData['banner_img'] ? (
                        <CardMedia
                            className={classes.media}
                            image={eventData['banner_img']}
                            title="eventData['name']"
                        />
                        ) : null}
                        <CardContent>
                            <Typography variant="h1">{eventData['name']}</Typography>
                            <Typography variant="h5">イベントID: {eventData['short_id']}</Typography>
                            {eventData["date"] && (
                                <Typography variant="body1" textoverflow="ellipsis">
                                    日時: {Utils.formatEventFromToDates(eventData["date"])}
                                </Typography>
                            )}
                            <Typography variant="body1" noWrap textoverflow="ellipsis">
                                集合場所: {eventData["start_point"] ? (eventData["start_point"]["name"] ?? "--") : "--"}
                            </Typography>
                            <Typography variant="body1" noWrap textoverflow="ellipsis">
                                URL:
                                <Link href={eventData["web_site"]} target="_blank">{eventData["web_site"] || ''}</Link>
                            </Typography>
                            <Chip label={eventData['is_full_function_restrict_event'] === true ?
                                'フル機能 許可制' : 'フル機能 全ユーザー利用可'} />
                            <Chip label={eventData['use_push_notification'] === true ?
                                'PUSH通知 利用可' : 'PUSH通知 利用不可'} />
                        </CardContent>
                    </Card>
                </Grid>
                
                {/* アクションボタン */}
                <Grid item xs={12}>
                    <DescribedButton
                        title="イベント参加登録者 一覧"
                        description="このイベントに参加登録を済ませたユーザーの確認や変更"
                        onClick={() => {history.push(`/event/${eventData["id"]}/registered_users`)}}
                    />
                </Grid>

                {eventData["is_controlled_event"] === true ? (
                    <Grid item xs={12}>
                        <Typography variant="h4" color="textSecondary">運営オンサイト機能</Typography>
                        <DescribedButton
                            title="参加者 受付"
                            description="イベント参加登録者の現地受付（出欠確認）"
                            onClick={() => {
                                history.push(`/event/${eventData["id"]}/check_in_reception`)
                            }}
                        />
                        <Divider style={Styles.spacerStyle}/>
                        <DescribedButton
                            title="参加者 ステータス"
                            description="参加者の現在位置やCP通過状況などのステータス確認"
                            onClick={() => {
                                history.push(`/event/${eventData["id"]}/event_status`)
                            }}
                        />
                        <Divider style={Styles.spacerStyle}/>
                        <DescribedButton
                            title="参加者 CP受付"
                            description="主催者による手動CP受付・管理"
                            onClick={() => {
                                history.push(`/event/${eventData["id"]}/check_point_register`)
                            }}
                        />
                        <Divider style={Styles.spacerStyle}/>

                        {eventData['use_push_notification'] === true &&
                        <>
                            <DescribedButton
                                title="PUSH通知 送信"
                                description="全イベント参加者へ一斉にPUSH通知を送信"
                                onClick={() => {
                                    history.push(`/event/${eventData["id"]}/send_notification`)
                                }}
                            />
                            <Divider style={Styles.spacerStyle}/>
                        </>
                        }
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <DescribedButton
                            title="参加者 ステータス"
                            description="参加者の現在位置などのステータス確認"
                            onClick={() => {
                                history.push(`/event/${eventData["id"]}/event_status`)
                            }}
                        />
                        <Divider style={Styles.spacerStyle}/>
                    </Grid>
                )}
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    media: {
        height: 0,
        paddingTop: '31.25%', // 16:5,
    }
}));
