import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Grid, List, makeStyles, Typography} from "@material-ui/core";

import * as Utils from "../../../Utils";
import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import EventStore from "../../../stores/EventStore";
import * as Styles from "../../../components/admin/Styles";
import EventUserRegister from "../../../components/admin/EventUserRegister";
import * as Firebase from "../../../stores/data_sources/Firebase";

export default function EventRegisteredUsersScreen() {
    const classes = useStyles();
    const params = useParams();
    const history = useHistory();
    const flashMessageContext = useContext(FlashMessageContext);
    const authValue = useContext(Firebase.AuthContext);

    const eventData = useRef({});
    const [title, setTitle] = useState('イベント参加登録者一覧');
    const [showLoading, setShowLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [deviceSummaryText, setDeviceSummaryText] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const eventId = params["event_id"];

    // 初期処理
    useEffect(() => {
        if (Utils.isAdmin(authValue)) {
            setIsAdmin(true);
        }

        retrieveEvent();
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // イベント検索
    const retrieveEvent = async () => {
        try {
            setShowLoading(true);

            // イベント情報検索
            const event = await EventStore.retrieveEvent(eventId);
            setTitle(event["name"] + '- 参加登録者一覧');
            eventData.current = event;

            // イベント情報 with 登録済みユーザー検索
            const eventRegisteredUsers = await EventStore.retrieveEventRegisteredUsers(eventId);
            setUserList(eventRegisteredUsers);

            // 雑に利用端末種別を取得
            let iosCount = 0, othersCount = 0, noDeviceInfo = 0;
            for (const user of eventRegisteredUsers) {
                if (user.device) {
                    if (/iOS/.test(user.device.os_name)) {
                        iosCount +=1;
                    } else {
                        othersCount += 1;
                    }
                } else {
                    noDeviceInfo +=1;
                }
            }
            setDeviceSummaryText(
                `全端末 = ${eventRegisteredUsers.length}台, iOS = ${iosCount}台, Android = ${othersCount}台, 不明 = ${noDeviceInfo}台`);
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoading(false);
        }
    };

    // ユーザー行選択時
    const onUserClick = async (user) => {
        history.push(`/event/${eventId}/registered_user/${user.id}`);
    };

    // チェックボックス選択時
    const onUserCheckClick = async (user) => {
        // フル機能制限イベントでなければ何もしない
        if (!eventData.current['is_full_function_restrict_event']) {
            return false;
        }

        if (window.confirm(`#${user['number']} ${user['name']} のフル機能利用OK/NGを変更します。\nよろしいですか？`)) {
            try {
                setShowLoading(true);

                let allowed = true;
                if (!!user['is_full_function_allowed']) {
                    allowed = false;
                }
                await EventStore.changeUserFullFunctionAllowedFlag(eventId, user.id, allowed);
                flashMessageContext.showMessage("フル機能利用OK/NGを変更しました", "success");
                await retrieveEvent();
            } catch (error) {
                console.error(error);
            } finally {
                setShowLoading(false);
            }
        }
    };

    return (
        <AdminBaseTemplate title={title} isShowLoadingModal={showLoading}>
            <Grid container className={classes.container} spacing={2}>
                {/* 検索・フィルター */}
                <Grid item xs={12}>
                    {/*<Typography variant="body2">(検索・フィルターはまだ未実装です)</Typography>*/}
                </Grid>

                {/* 参加者一覧 */}
                <Grid item xs={12}>
                    <Typography variant="h6">イベント参加登録者一覧</Typography>
                    <List className={classes.listContainer}>
                        {userList.map(user => (
                            <EventUserRegister
                                key={user["id"]}
                                eventData={eventData.current}
                                userData={user}
                                showUserDetails={true}
                                onRowClick={isAdmin ? onUserClick : null}
                                // チェックボックス系
                                checkLabel={eventData.current['road_book_versions'] ? "フル機能OK" : null}
                                checkDescription={"受付:" + Utils.formatShortDateTime(Utils.parseDate(user["entry_at"]))}
                                onCheckClick={onUserCheckClick}
                                isChecked={
                                    eventData.current['is_full_function_restrict_event'] !== true ||
                                        user['is_full_function_allowed'] === true
                                }
                            />
                        ))}
                    </List>
                </Grid>

                {/* 利用機種サマリ */}
                <Grid item xs={12}>
                    <p>{deviceSummaryText}</p>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
    listContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    number: {
        textAlign: "center",
        paddingRight: '0.5rem',
    },
}));
