import React, {createContext, useContext, useState} from 'react'
import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

export const FlashMessageContext = createContext({
    isShowFlashMessage: false,
    setIsShowFlashMessage(isShowFlashMessage) {},
    message: '',
    severity: 'success',
    showMessage(argMessage, argSeverity) {},
    hideMessage() {}
});

export function FlashMessageContextProvider(props) {
    const [isShowFlashMessage, setIsShowFlashMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    
    // メッセージ表示
    const showMessage = (argMessage, argSeverity) => {
        setMessage(argMessage);
        setSeverity(argSeverity);
        setIsShowFlashMessage(true);
    };
    
    // メッセージ非表示
    const hideMessage = () => {
        setIsShowFlashMessage(false);
    }
    
    return (
        <FlashMessageContext.Provider
            value={{
                isShowFlashMessage,
                setIsShowFlashMessage,
                message,
                severity,
                showMessage,
                hideMessage
            }}
        >
            {props.children}
        </FlashMessageContext.Provider>
    )
}

export function FlashMessage() {
    const {message, severity, isShowFlashMessage, setIsShowFlashMessage} = useContext(FlashMessageContext);
    
    const onClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsShowFlashMessage(false);
    };
    
    return (
        <Snackbar open={isShowFlashMessage} autoHideDuration={2500} onClose={onClose}>
            <MuiAlert elevation={6} variant="filled" severity={severity} onClose={onClose}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}
