import React, {useContext} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {AuthContext, AuthContextProvider} from "./stores/data_sources/Firebase";
import {FlashMessageContextProvider} from "./components/admin/FlashMessage";
import LoginScreen from "./screens/admin/LoginScreen";
import HomeScreen from "./screens/admin/HomeScreen";
import EventHomeScreen from "./screens/admin/event/EventHomeScreen";
import EventRegisteredUsersScreen from "./screens/admin/event/EventRegisteredUsersScreen";
import EventRegisteredUserEditScreen from "./screens/admin/event/EventRegisteredUserEditScreen";
import EventRegisterReceptionScreen from "./screens/admin/event/EventRegisterReceptionScreen";
import EventStatusMainScreen from "./screens/admin/event/EventStatusMainScreen";
import EventCheckPointStatusByUserScreen from "./screens/admin/event/EventCheckPointStatusByUserScreen";
import EventRegisterCheckPointMainScreen from "./screens/admin/event/EventRegisterCheckPointMainScreen";
import EventRegisterCheckPointScreen from "./screens/admin/event/EventRegisterCheckPointScreen";
import EventPushNotificationScreen from "./screens/admin/event/EventPushNotificationScreen";
import EventRegisterScreen from "./screens/admin/event/EventRegisterScreen";
import EventQrDisplayScreen from "./screens/admin/event/EventQrDisplayScreen";

let theme = createTheme({
    palette: {
        primary: {
            main: '#00c1c1',
            contrastText: '#fff'
        },
        secondary: {
            main: '#008e8e',
            contrastText: '#fff'
        },
    },
    typography: {
        h1: {
            fontSize: "1.8rem",
            fontWeight: 'bolder'
        },
        h2: {
            fontSize: "1.67rem",
            fontWeight: 'bold'
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 'bold'
        },
        h4: {
            fontSize: "1.35rem",
            fontWeight: 'bold'
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 'bold'
        },
        h6: {
            fontSize: "1.1rem",
            fontWeight: 'bold'
        },
    },
});

function ProtectedRoute(props) {
    const authValue = useContext(AuthContext);
    if (authValue.userDataPresent) {
        if (authValue.user == null) {
            return (
                <Redirect to={props["redirectTo"] || "/login"} />
            );
        } else {
            return (
                <Route exact path={props.path}>
                    {props.children}
                </Route>
            );
        }
    } else {
        return null
    }
}

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AuthContextProvider>
                <FlashMessageContextProvider>
                    <Router>
                        <Switch>
                            <Route exact path="/login">
                                <LoginScreen />
                            </Route>
                            <ProtectedRoute exact path="/">
                                <Redirect to="/home" />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/home">
                                <HomeScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id">
                                <EventHomeScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/registered_users">
                                <EventRegisteredUsersScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/registered_user/:user_id">
                                <EventRegisteredUserEditScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/check_in_reception">
                                <EventRegisterReceptionScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/event_status">
                                <EventStatusMainScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/check_point_status_by_user">
                                <EventCheckPointStatusByUserScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/check_point_register">
                                <EventRegisterCheckPointMainScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/check_point_register/:check_point_id">
                                <EventRegisterCheckPointScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/:event_id/send_notification">
                                <EventPushNotificationScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/register/new">
                                <EventRegisterScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/register/update/:event_id">
                                <EventRegisterScreen />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/event/qr/show/:event_id">
                                <EventQrDisplayScreen />
                            </ProtectedRoute>
                        </Switch>
                    </Router>
                </FlashMessageContextProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
}
export default App;
