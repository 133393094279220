import * as _ from 'lodash';
import * as Firebase from '../stores/data_sources/Firebase';
import * as Utils from '../Utils';

function createEventStore() {
    const db = Firebase.instance.firestore();
    
    return {
        // 自身が管理するイベント一覧取得
        async retrieveOwnEvents() {
            // 自身のroleに応じて検索方法が変わる
            const idTokenResult= await Firebase.retrieveCurrentUser();

            let eventQuery;
            if (idTokenResult.role === 'root') {
                eventQuery = db.collection(Firebase.V + 'events');
            } else if (idTokenResult.role === 'admin') {
                eventQuery = db.collection(Firebase.V + 'events')
                    .where('admin_uid', '==', idTokenResult.user_id);
            } else if (idTokenResult.role === 'staff') {
                eventQuery = db.collection(Firebase.V + 'events')
                    .where('admin_uid', 'in', idTokenResult.admin_uid_list);
            } else {
                return [];
            }
            const eventListRef = await eventQuery.get();

            const events = [];
            for (let i = 0; i < eventListRef.size; i++) {
                events.push({
                    id: eventListRef.docs[i].id,
                    ...eventListRef.docs[i].data()
                });
            }

            // 開催開始日でソート
            return events.sort((a, b) => {
                const f = (data) => {
                    if (_.isArray(data['date']) && data['date'].length > 0) {
                        const dt = new Date(data['date'][0]);
                        if (isNaN(dt)) {
                            return 0;
                        } else {
                            return dt.getTime();
                        }
                    } else {
                        return 0;
                    }
                }
                return f(b) - f(a);
            });
        },
        
        // イベントを取得
        async retrieveEvent(eventId) {
            const eventRef = await db.collection(Firebase.V + 'events').doc(eventId).get();
            if (eventRef.exists) {
                return {
                    id: eventRef.id,
                    ...eventRef.data()
                }
            } else {
                return null;
            }
        },
        
        // イベントのチェックポイント情報を取得
        async retrieveEventCheckPoints(eventId) {
            const checkPointsSnapshot =
                await db.collection(Firebase.V + 'events').doc(eventId).collection("check_points").get();
            
            const checkPoints = [];
            for (let i = 0; i < checkPointsSnapshot.size; i++) {
                checkPoints.push({
                    id: checkPointsSnapshot.docs[i].id,
                    ...checkPointsSnapshot.docs[i].data(),
                })
            }

            return checkPoints.sort((a, b) => a['order'] - b['order']);
        },

        // イベント参加ユーザー取得
        async retrieveEventRegisteredUsers(eventId, onlyCheckInReceptionUser = false) {
            let userQuery;
            if (!onlyCheckInReceptionUser) {
                // 参加登録済み全ユーザー検索
                userQuery = db.collection(Firebase.V + 'events').doc(eventId)
                    .collection('entry_users');
            } else {
                // 参加登録済み AND 当日受付済みユーザーのみ検索
                userQuery = db.collection(Firebase.V + 'events').doc(eventId)
                    .collection('entry_users').where('is_reception_done', '==', true);
            }
            
            const usersRef = await userQuery.get();
            const userDataList = [];
            usersRef.forEach((userRef) => {
                userDataList.push({
                    id: userRef.id,
                    ...userRef.data()
                });
            });

            // 番号順でソートして返却
            return userDataList.sort((a, b) => {
                const convertFn = (numStr) => {
                    const num = Number(numStr);
                    if (Number.isNaN(num)) {
                        return 999999;
                    }
                    // 頭がゼロではじまる番号は運営（なんか勝手ルールでそうなった）ので一番最後に回す
                    if (Utils.isStaff(numStr)) {
                        return num + 10000;
                    }
                    return num;
                };
                return convertFn(a['number']) - convertFn(b['number']);
            });
        },
        
        // チェックポイント通過サマリー取得
        async retrieveCheckPointPassedSummary(eventId) {
            // 事前にイベントサマリーデータ#受付済みユーザー数を取得しておく
            const usersCountSummaryDataRef = await db.collection(Firebase.V + 'events')
                .doc(eventId).collection('event_summaries').doc('RECEPTION_DONE_USERS_COUNT').get();
            const usersCountSummaryData = usersCountSummaryDataRef.data() || {};
            
            // 事前にイベントサマリーデータ#チェックイン済みユーザー数を取得しておく
            const checkInCountsRef = await db.collection(Firebase.V + 'events')
                .doc(eventId).collection('event_summaries').doc('CHECKIN_USERS_COUNT').get();
            const checkInCountsData = checkInCountsRef.data() || {};

            // DEV: ↑はすでにサマってるデータ。ここからスタッフ数を除外するという、かなり汚いし危うい実装をしてしまってます
            // イベント参加ユーザーを取得して、スタッフ人数を数える
            const eventRegisteredUsers = await this.retrieveEventRegisteredUsers(eventId);
            const staffCount = eventRegisteredUsers.filter(user => Utils.isStaff(user["number"])).length;
            
            // チェックポイント一覧取得
            // チェックポイントが含まれるカテゴリを取得し、そのカテゴリに参加している人数を取得
            const checkPointDataModifyList = [];
            const checkPointDataList = await this.retrieveEventCheckPoints(eventId);
            for (let i = 0; i < checkPointDataList.length; i++) {
                let checkPointTargetUserCount = 0;
                const checkPointData = checkPointDataList[i];
                
                // チェックポイントが含まれるカテゴリーの参加人数を加算
                for (const category of checkPointData["categories"]) {
                    if (category in usersCountSummaryData) {
                        checkPointTargetUserCount += usersCountSummaryData[category];
                    }
                }
                
                // すでにチェックイン済み人数を取得
                let checkedInCount = 0;
                if (checkPointData.id in checkInCountsData) {
                    checkedInCount = checkInCountsData[checkPointData.id];
                }

                // DEV: すでにサマってるデータからスタッフ数を除外するという、かなり汚いし危うい実装をしてしまってます
                checkPointDataModifyList.push({
                    ...checkPointData,
                    checked_in_user_count: checkedInCount,
                    target_user_count: checkPointTargetUserCount - staffCount,
                });
            }
            
            return checkPointDataModifyList;
        },
        
        // イベント参加者の現在位置取得
        async retrieveUsersCurrentLocation(eventId, onlyCheckInReceptionUser = true) {
            // イベント受付済みユーザーを取得
            const users = await this.retrieveEventRegisteredUsers(eventId, onlyCheckInReceptionUser);
            
            // 各ユーザーの最新位置情報を取得
            const usersLocation = [];
            const usersLocationSs =
                await db.collection(Firebase.V + 'events').doc(eventId).collection('user_locations').get();
            usersLocationSs.forEach((userLocation) => {
                usersLocation.push({
                    id: userLocation.id,
                    ...userLocation.data()
                });
            });
            
            // イベント受付済みユーザーを基準として、最新位置情報を追加
            for (let i = 0; i < users.length; i++) {
                const findUserLocations = usersLocation.filter(userLocation => userLocation.id === users[i]["id"]);
                if (findUserLocations.length > 0) {
                    users[i]["current_location"] = findUserLocations[0];
                }
            }

            return users;
        },
        
        // ユーザーフル機能利用可否フラグ変更
        async changeUserFullFunctionAllowedFlag(eventId, userId, isAllowed = false) {
            // 該当ユーザー取得
            const userRef = db.collection(Firebase.V + 'events').doc(eventId).collection('entry_users').doc(userId);
            const userSnapshot = await userRef.get();
            if (userSnapshot.exists) {
                await userRef.set({is_full_function_allowed: isAllowed}, {merge: true});
            }
        },
        
        // ユーザー受付ステータス変更
        async changeUserCheckInReceptionStatus(eventId, userId, isCheckIn = false) {
            const isCheckInDate = isCheckIn ? new Date() : null;
            
            // 該当ユーザー取得
            const userRef = db.collection(Firebase.V + 'events').doc(eventId).collection('entry_users').doc(userId);
            const userSnapshot = await userRef.get();
            const userData = userSnapshot.data();
            
            const batch = db.batch();
            
            // イベント>エントリーユーザーの該当ユーザーに受付済みフラグを立てる
            batch.update(userRef, {
                "is_reception_done": isCheckIn,
                "reception_at": isCheckInDate
            });
            
            // イベントサマリーを更新
            const eventSummaryUpdateData = {
                "_USERS_COUNT": Firebase.instance.firestore.FieldValue.increment((isCheckIn ? 1 : -1))
            };
            if (userData["categories"] && _.isArray(userData["categories"])) {
                for (const category of userData["categories"]) {
                    eventSummaryUpdateData[category] = Firebase.instance.firestore.FieldValue.increment((isCheckIn ? 1 : -1));
                }
            }
            
            batch.set(
                db.collection(Firebase.V + 'events').doc(eventId).collection('event_summaries').doc('RECEPTION_DONE_USERS_COUNT'),
                eventSummaryUpdateData,
                {merge:true}
            );
            
            await batch.commit();
        },
        
        // ユーザーチェックポイント受付ステータス変更
        async changeUserCheckPointStatus(eventId, userId, checkPointId, isCheckIn = false) {
            // 該当ユーザー取得
            const userSnapShot =
                await db.collection(Firebase.V + 'events').doc(eventId).collection('entry_users').doc(userId).get();
            const userData = userSnapShot.data();
            
            const batch = db.batch();
            
            // イベント#エントリーユーザーの該当CP受付状態を事前に取得
            const userCheckPointData = userData["check_points"] || {};
            const isAlreadyPass = !!userCheckPointData[checkPointId];
            
            if (isCheckIn) {
                // イベント#エントリーユーザーにCP受付済みフラグを立てる
                userCheckPointData[checkPointId] = {
                    latitude: null,
                    longitude: null,
                    check_in_at: new Date(),
                    check_value: null,
                    registered_by_admin: true,
                }
            } else if (isAlreadyPass) {
                // チェックインOFF && すでに存在するなら削除
                delete userCheckPointData[checkPointId];
            }
            batch.update(userSnapShot.ref, "check_points", userCheckPointData);
            
            // イベントサマリーを更新（ただしすでにチェックイン済みなら加算しない）
            if (!(isCheckIn && isAlreadyPass)) {
                batch.set(db.collection(Firebase.V + 'events').doc(eventId)
                    .collection('event_summaries').doc('CHECKIN_USERS_COUNT'),
                    { [checkPointId]: Firebase.instance.firestore.FieldValue.increment(isCheckIn ? 1 : -1) },
                    {merge:true}
                );
            }
            
            await batch.commit();
        },
        
        // イベント情報からカテゴリ名取得
        getCategoryName(eventData, categoryId) {
            if (!_.isArray(eventData["categories"])) {
                return '';
            }
            for (let i = 0; i < eventData["categories"].length; i++) {
                if (eventData["categories"][i]["id"] === categoryId) {
                    return eventData["categories"][i]["name"];
                }
            }
            return '';
        },

        async retrieveEventUserLocationHiddenFlag(eventId) {
            let result = false;
            const eventData = await this.retrieveEvent(eventId);
            if (eventData && eventData["user_location_hidden"] === true) {
                result = true;
            }
            return result;
        },

        async setEventUserLocationHiddenFlag(eventId, isHidden) {
            const eventSs = await db.collection(Firebase.V + 'events').doc(eventId).get();
            if (eventSs.exists) {
                await eventSs.ref.set({"user_location_hidden": isHidden}, {merge: true});
            }
        }
    }
}
export default createEventStore();
