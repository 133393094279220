import React, {useContext, useEffect} from "react";
import {Button, Grid, makeStyles, TextField, TextFieldProps} from "@material-ui/core";
import {useForm, SubmitHandler, Controller} from "react-hook-form";

import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import {FlashMessageContext} from "../../../components/admin/FlashMessage";
import * as Styles from "../../../components/admin/Styles";
import EventStore2, {EventRegisteredUserData} from "../../../stores/EventStore2";
import {useParams} from "react-router-dom";

type Params = {
    event_id: string,
    user_id: string,
};

export default function EventRegisteredUserEditScreen() {
    const classes = useStyles();
    const params = useParams<Params>();
    const flashMessageContext = useContext(FlashMessageContext);

    // フォーム設定
    const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            name: '',
            number: '',
            mobile_number: '',
            vehicle_model: '',
        },
    });

    // 初期処理
    useEffect(() => {
        // データ取得
        EventStore2.retrieveEventRegisteredUser(params.event_id, params.user_id).then((userData) => {
            if (!userData) {
                return;
            }

            // データ詰め替え
            const formData: FormData = {
                name: userData.name,
                number: userData.number,
                mobile_number: userData.mobile_number,
                vehicle_model: userData.vehicle_model,
            };
            reset(formData);
        });
    }, []);

    // Submit時処理
    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        try {
            // データ詰め替え
            const data = {} as EventRegisteredUserData;
            data.name = formData.name;
            data.number = formData.number;
            data.mobile_number = formData.mobile_number;
            data.vehicle_model = formData.vehicle_model;

            // データ登録
            const errorMessage = await EventStore2.editEventRegisteredUser(params.event_id, params.user_id, data);
            if (errorMessage) {
                flashMessageContext.showMessage(`参加者情報の変更でエラーが発生しました。${errorMessage}`, "error");
                return;
            }
            flashMessageContext.showMessage(`参加者情報の変更が完了しました`);
        } catch (e) {
            flashMessageContext.showMessage("参加者情報の変更で異常が発生しました", "error");
            console.error(e);
        }
    };

    // 削除ボタン押下時
    // TODO: 削除は面倒になった（サマリーデータ削除とか）ので後回し。実装する場合はスマフォAppのイベント登録削除処理を参照する
    const onDelete = async () => {
        if (window.confirm(
            '本当にこの参加者を削除してよろしいですか？\n' +
            `この操作は取り消すことができません`)) {
            // const errorMessage = await EventStore2.deleteEvent(savedEventId);
            // if (errorMessage) {
            //     flashMessageContext.showMessage(`イベント削除でエラーが発生しました。${errorMessage}`, "error");
            //     return;
            // }
            // flashMessageContext.showMessage("イベントを削除しました");
            // history.push(`/`);
        }
    };

    return (
        <AdminBaseTemplate
            title='参加ユーザー情報変更'
            rightButton={null}
            isShowLoadingModal={false}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container className={classes.container} spacing={2}>
                    <Grid item xs={12} sm={10}>
                        {/* ゼッケン */}
                        <Controller
                            control={control}
                            name="number"
                            rules={{
                                required: { value: true, message: "必須入力です" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="ゼッケン*"
                                    helperText={!!errors.number ? errors.number?.message : "ゼッケンの数字がゼロはじまりの場合は運営スタッフとして扱われます"}
                                    error={!!errors.number}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        {/* 参加者名 */}
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: { value: true, message: "必須入力です" },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="参加者名*"
                                    helperText={!!errors.name ? errors.name?.message : "イベント参加者名"}
                                    error={!!errors.name}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        {/* 車両名 */}
                        <Controller
                            control={control}
                            name="vehicle_model"
                            rules={{}}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="車両名"
                                    helperText={!!errors.vehicle_model ? errors.vehicle_model?.message : "イベント時の連絡先となる携帯電話番号"}
                                    error={!!errors.vehicle_model}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />

                        {/* 携帯番号 */}
                        <Controller
                            control={control}
                            name="mobile_number"
                            rules={{}}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="携帯電話番号"
                                    helperText={!!errors.mobile_number ? errors.mobile_number?.message : "イベント時の連絡先となる携帯電話番号"}
                                    error={!!errors.mobile_number}

                                    {...commonTextFieldProps}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-end">
                    {/*<Button variant="outlined" onClick={onDelete}>削除</Button>*/}
                    <Button type="submit" variant="contained" color="secondary" size="large">登録</Button>
                </Grid>
            </form>
        </AdminBaseTemplate>
    );
}

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
}));

type FormData = {
    name: string,
    number: string,
    mobile_number: string,
    vehicle_model: string,
};

const commonTextFieldProps: TextFieldProps = {
    autoComplete: "off",
    InputLabelProps: { shrink: true },
    margin: "normal",
    fullWidth: true,
};
